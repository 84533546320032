@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@font-face {
    font-family: 'Khmer MN';
    src: url('./assets/fonts/KhmerMN-Bold.woff2') format('woff2'),
        url('./assets/fonts/KhmerMN-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Khmer MN';
    src: url('./assets/fonts/KhmerMN.woff2') format('woff2'),
        url('./assets/fonts/KhmerMN.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

.Toastify {
    font-family: 'Khmer MN';
}

nav#dashboard-nav * {
    font-size: 16px;
}

#dashboard * {
    font-family: "Inter", sans-serif;
}

#dashboard .MuiButton-contained {
    border-radius: 0px;
}

body {
    line-height: 1;
    color: #000;
    p {
        alignment-baseline: central;
    }
}

.MuiTable-root a:hover {
    text-decoration: underline;
}


button[component="button"], button[type="submit"] {
    line-height: 1 !important;
    padding: 18px 22px !important;
    border-radius: 0px !important;
}

fieldset {
    border-radius: 0px !important;
}

@media (max-width: 768px) {
    .bottom-nav button span.MuiBottomNavigationAction-label {
        font-size: 12px !important;
        margin-top: 2px;
    }
}